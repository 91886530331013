import React from "react"
import { graphql } from "gatsby"
import { Layout, SEO } from "@global"
import { Card } from "@elements"

export default ({ data }) => {
  const releases = data.wpgraphql.videos.edges

  return (
    <Layout>
      <SEO title="Releases" />
      <section>
        <div className="container px-4 pt-20">
          <h1 className="text-3xl leading-tight tracking-tight sm:text-4xl lg:text-5xl font-display text-center">
            Video Archive
          </h1>
        </div>
      </section>
      <section className="container py-10 lg:py-20 px-4">
        <div className="flex flex-wrap lg:justify-center">
          {releases.map(({ node }) => {
            return (
              <div
                key={node.id}
                className="w-full max-w-lg mx-auto mt-6 lg:w-1/3 lg:mt-0 lg:mb-6 lg:px-2 lg:mx-0 lg:max-w-md"
              >
                <Card
                  title={node.title}
                  image={node.featuredImage && node.featuredImage.sourceUrl}
                  altText={node.featuredImage ? node.featuredImage.altText : ``}
                  linkUrl={node.uri}
                />
              </div>
            )
          })}
        </div>
      </section>
    </Layout>
  )
}

export const VideoArchiveQuery = graphql`
  query VideoArchiveQuery {
    wpgraphql {
      videos(first: 1000) {
        edges {
          node {
            id
            uri
            status
            slug
            title
            featuredImage {
              altText
              sourceUrl
            }
          }
        }
      }
    }
  }
`
